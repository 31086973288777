import React, { Component } from "react"
import {Link} from 'gatsby';
// import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./casestudydxc.scss"

import imgQuote from "../images/quotation_mark.webp"

export default ({ pageContext }) => (
  <Layout>
    <SEO
      title={pageContext.yoast.title}
      description={pageContext.yoast.metadesc}
      ogimg={pageContext.yoast.opengraph_image}
      link={pageContext.link}
    />
    <div className="case-study-one-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div
            className="header-container"
            style={{
              backgroundImage: `url(${pageContext.acf.header_image.csOneHedImaLocalFile.childImageSharp.fluid.src})`,
            }}
          >
            <img src={imgQuote} alt="" />
            <h2>{pageContext.acf.header_text}</h2>
          </div>
        </div>
      </div>
      <div className="container detail-wrapper">
        <div className="row">
          <div className="col-12">
            <img
              src={
                pageContext.acf.client_logo.csOneCliLogLocalFile.childImageSharp
                  .fluid.src
              }
              className="client-logo"
              width={pageContext.acf.client_logo.media_details.width / 2}
              height={pageContext.acf.client_logo.media_details.height / 2}
              alt={pageContext.yoast.opengraph_title}
            />
          </div>
          <div className="col-12">
            <div className="bar"></div>
          </div>
          <div className="col-12">
            <div
              className="first-paragraph"
              dangerouslySetInnerHTML={{
                __html: pageContext.acf.first_paragraph,
              }}
            />
            <a href="https://www.meritmile.com/uploads/2021/11/merit-mile-dxc-technology-case-study.pdf" className="btn sitebtn" target="_blank" rel="nofollow noopener noreferrer">Read the Full Case Study</a>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div
              className="second-paragraph"
              dangerouslySetInnerHTML={{
                __html: pageContext.acf.second_paragraph,
              }}
            />
          </div>
        </div>
      </div>
      <div style={{ overflow: 'hidden' }}>
        <div className="container detail-wrapper-two" >
          <div className="row">
            <div className="col-md-6">
              <div
                className="third-paragraph"
                dangerouslySetInnerHTML={{
                  __html: pageContext.acf.third_paragraph,
                }}
              />
            </div>
            <div className="col-md-6 overflowImage">
              <img
                src="https://www.meritmile.com/uploads/2021/10/dxc-cs-solutions_banner.jpg"
                alt="DXC Solutions"
                className="dxcSolutions"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container detail-wrapper">
        <div className="row">
          <div className="col-md-6">
            <img
              src="https://www.meritmile.com/uploads/2021/10/dxc_work-samples.jpg"
              alt="These included"
              className="img-fluid theseIncluded"
            />
          </div>
          <div className="col-md-6">
            <div className="fifth-paragraph">
              <p><b style={{ fontWeight: '600'}}>These included</b></p>
              <ol>
                <li>
                  Designing and building the DXC Cloud Marketplace and creating supporting resources (Overview videos, Migration Plans, Solution Briefs, Datasheets, Infographics) to demonstrate domain expertise.
                </li>
                <li>
                  Creating a highly targeted email marketing campaign with specific messaging around Azure migration to drive qualified service provider traffic to the new DXC Cloud Marketplace.
                </li>
                <li>
                  Developing a persuasive LinkedIn demand generation campaign with thoughtful resources to recruit SPLA partners from competing resellers into DXC.
                </li>
                <li>
                  Making introductions to key targeted trade media to extend the DXC message for editorial and thought leadership.
                </li>
                <li>
                  Generating integrated, cross-promotional social content opportunities to help DXC	participate in and even drive the cloud services, Azure and SPLA marketplace conversation.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="fullWidthImageWrapper">
        <img
          src="https://www.meritmile.com/uploads/2021/10/dxc-cs-divider_banner.jpg"
          alt="Merit Mile Icon Divider"
          className="img-fluid"
        />
      </div>
      <div className="container detail-wrapper">
        <div className="row">
          <div className="col-12">
            <div className="fourth-paragraph">
              <h3>Results</h3>
              <p>Although specific KPIs are non-disclosure protected, by working with Merit Mile, DXC was able to establish an identity as a full-service Cloud provider and effectively showcase their entire suite of Cloud and SPLA offerings through a thoughtfully designed online destination and custom-built marketplace.  They were also equipped to deploy effective campaign strategies, hard-hitting messaging, and compelling offers to capture targeted leads and qualified prospects to support their business objectives.</p>
            </div>
          </div>
        </div>
        <div className="row fourSlots">
          <div className="col-md-3 text-center">
            <img
              src="https://www.meritmile.com/uploads/2021/10/dxc-cs-var_web.jpg"
              alt="VAR Insights Website"
              className="img-fluid websiteImage"
            />

            <div className="logoImage">
              <img
                src="https://www.meritmile.com/uploads/2021/10/dxc-cs-var_insights.jpg"
                alt="VAR Insights Logo"
                className="img-fluid"
                style={{ margin: '5px 0' }}
              />
            </div>

            <p>VARinsights.com</p>
          </div>
          <div className="col-md-3 text-center">
            <img
              src="https://www.meritmile.com/uploads/2021/10/dxc-cs-hio_web.jpg"
              alt="Health IT Outcomes Website"
              className="img-fluid websiteImage"
            />

            <div className="logoImage">
              <img
                src="https://www.meritmile.com/uploads/2021/10/dxc-cs-health_it-outcomes.jpg"
                alt="Health IT Outcomes Logo"
                className="img-fluid"
                style={{ margin: '10px 0' }}
              />
            </div>

            <p>Healthitoutcomes.com</p>
          </div>
          <div className="col-md-3 text-center">
            <img
              src="https://www.meritmile.com/uploads/2021/10/dxc-cs-hbt_web.jpg"
              alt="Healthcare Business Today Website"
              className="img-fluid websiteImage"
            />

            <div className="logoImage">
              <img
                src="https://www.meritmile.com/uploads/2021/10/dxc-cs-healthcare_bt.jpg"
                alt="Healthcare Business Today Logo"
                className="img-fluid"
              />
            </div>

            <p>Healthcarebusinesstoday.com</p>
          </div>
          <div className="col-md-3 text-center">
            <img
              src="https://www.meritmile.com/uploads/2021/10/dxc-cs-msp_web.jpg"
              alt="MSP Insights Website"
              className="img-fluid websiteImage"
            />

            <div className="logoImage">
              <img
                src="https://www.meritmile.com/uploads/2021/10/dxc-cs-msp_insights.jpg"
                alt="MSP Insights Logo"
                className="img-fluid"
                style={{ margin: '10px 0' }}
              />
            </div>

            <p>MSPInsights.com</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="fifth-paragraph">
              <h3>Microsoft Azure and Microsoft SPLA Results</h3>
              <p>Through the Microsoft Azure campaign, DXC generated significant demand among existing hosting providers and conducted conversations with North American and European partner prospects about the benefits of migrating workloads to Azure. The SPLA LinkedIn campaign generated awareness as well as new interest and engagement from prominent SPLA customers and the thought leadership content articles provided insight, education, and promotion of DXC’s core solutions in front of targeted audiences throughout the industry.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid extra-wider-content">
        <div className="row">
          <div className="col-md-4 text-center">
            <img
              src="https://www.meritmile.com/uploads/2021/10/dxc_solution-brief.jpg"
              alt="Merit Mile Icon Divider"
              width="435"
              className="img-fluid"
            />
          </div>
          <div className="col-md-4 text-center">
            <img
              src="https://www.meritmile.com/uploads/2021/10/dxc_web.jpg"
              alt="Merit Mile Icon Divider"
              width="690"
              className="img-fluid"
            />
          </div>
          <div className="col-md-4 text-center">
            <img
              src="https://www.meritmile.com/uploads/2021/10/dxc_emails.jpg"
              alt="Merit Mile Icon Divider"
              width="365"
              className="img-fluid"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mx-auto text-center">
            <Link className="btn btn-primary btn-lg btn-block" to="https://www.meritmile.com/uploads/2021/11/merit-mile-dxc-technology-case-study.pdf" target="_blank">Download the Case Study</Link>
          </div>
        </div>
      </div>
      <div className="fullWidthImageWrapper">
        <img
          src="https://www.meritmile.com/uploads/2021/09/mm-divider.jpg"
          alt="Merit Mile Icon Divider"
          className="img-fluid"
        />
      </div>
      <div className="about-wrapper">
          <div className="container">
              <div className="row">
                  <div className="col">
                      <h2>About Merit Mile</h2>
                  </div>
              </div>
              <div className="row margin-b">
                  <div className="col-md-6">
                      <p>Established in 2007 in Boca Raton, FL, Merit Mile is a marketing services provider focused on helping companies and corporate marketing departments build brands, drive sales revenue, and garner publicity in their respective industries. Our unique differentiator at Merit Mile is our ability to 100% own the message, the publicity, and the demand generation aspects of the corporate marketing function. Clients hire us and rehire us for our ability to refine their messaging/value proposition, develop top and mid-funnel leads, and get the type of editorial publicity that differentiates them from their competitors. From branding to PR to demand generation, Merit Mile is the high-octane fuel that invigorates corporate growth engines. To learn more, please email us at Hello@MeritMile.com, call us at 1-561-362-888, or visit us at www.MeritMile.com. </p>
                  </div>
                  <div className="col-md-6"><img className="img-fluid" src="https://www.meritmile.com/uploads/2021/06/mm-headquater.jpg" alt="Merit Mile Headquarter in Boca Raton, FL" /></div>
              </div>
          </div>
      </div>
      <div className="container quote-wrapper">
        <div className="row">
          <div className="col-12">
            <p>
              For more insights and to connect<br />
              with Merit Mile <a href="mailto:mailto:Hello@MeritMile.com?subject=DXC%20Case%20Study%20Inquiry"><b style={{ color: "black" }}>email us here</b>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
